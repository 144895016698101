import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import Image from '../elements/Image';

import { PrismicRichText, useFirstPrismicDocument } from '@prismicio/react'

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  // const [document] = useFirstPrismicDocument()

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="image-container">
        <div className="tag">
          <h3 className="h5-mobile">
            {/* {document && (
              <PrismicRichText field={document.data.body[0].primary.eyebrow_headline} />
            )} */}
            Our partners believe in us
          </h3>
        </div>
      </div>
      <div className="container">
        <div className={innerClasses}>
          <div className={splitClasses}>
            <div className="partners">
              <div className="partners-row">
                <div className="partner">
                  <Image
                    src={require(`./../../assets/images/partner-1.svg`).default}
                    alt="journey-2"
                    width={190}
                    height={92}
                  />
                </div>
                <div className="partner">
                  <Image
                    src={require(`./../../assets/images/partner-2.svg`).default}
                    alt="journey-2"
                    width={231}
                    height={158}
                  />
                </div>
              </div>
              <div className="partners-row">
                <div className="partner">
                  <Image
                    src={require(`./../../assets/images/partner-3.svg`).default}
                    alt="journey-2"
                    width={178}
                    height={92}
                  />
                </div>
                <div className="partner">
                  <Image
                    src={require(`./../../assets/images/partner-4.svg`).default}
                    alt="journey-2"
                    width={295}
                    height={74}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;