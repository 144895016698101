import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Team = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'team section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'team-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'team-split'
  );

  const sectionHeader = {
    title: 'A word from us'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="image-container" />
      <div className="container">
        <div className={innerClasses}>
          <div className="team-content">
            <div className="team-text">
              <SectionHeader data={sectionHeader} className="p-0 pb-16" />
              <p className="m-0 mb-16">Hello Doer,</p>
              <p className="m-0 mb-16">
                Happy to see you! One challenge at a time,
                our app makes it easy for you to develop your skills,
                no matter where you are or what you’re doing.
                That’s why we created it for you, so you can do something
                productive every day and have fun doing it.
              </p>
              <h5>
                DO-IT Team
              </h5>
            </div>
            <div className="team-cards">
              <div className="team-cards-row">
                <div className="team-card">
                  <Image src={require('./../../assets/images/team-1.png')}
                    alt="Daria"
                    width={175}
                    height={175} />
                  <div className="team-card-text">
                    <h6 className="mb-4">Daria Cupăreanu</h6>
                    <p className="text-xxs">CEO of DO-IT</p>
                  </div>
                </div>

                <div className="team-card">
                  <Image src={require('./../../assets/images/team-2.png')}
                    alt="Razvan"
                    width={175}
                    height={175} />
                  <div className="team-card-text">
                    <h6 className="mb-4">Răzvan Măhălean</h6>
                    <p className="text-xxs">Backend Developer</p>
                  </div>
                </div>

                <div className="team-card">
                  <Image src={require('./../../assets/images/team-3.png')}
                    alt="Robert"
                    width={175}
                    height={175} />
                  <div className="team-card-text">
                    <h6 className="mb-4">Robert Irimia</h6>
                    <p className="text-xxs">Backend Developer</p>
                  </div>
                </div>
              </div>

              <div className="team-cards-row">
                <div className="team-card">
                  <Image src={require('./../../assets/images/team-4.png')}
                    alt="Anca"
                    width={175}
                    height={175} />
                  <div className="team-card-text">
                    <h6 className="mb-4">Anca Suliman</h6>
                    <p className="text-xxs">Frontend Developer</p>
                  </div>
                </div>

                <div className="team-card">
                  <Image src={require('./../../assets/images/team-5.png')}
                    alt="Sabin"
                    width={175}
                    height={175} />
                  <div className="team-card-text">
                    <h6 className="mb-4">Sabin Țenea</h6>
                    <p className="text-xxs">UI/UX Designer</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;
