import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
    ...SectionProps.types,
    split: PropTypes.bool
}

const defaultProps = {
    ...SectionProps.defaults,
    split: false
}

const Cta = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    split,
    ...props
}) => {

    const outerClasses = classNames(
        'community section center-content-mobile',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'community-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider',
        split && 'community-split'
    );

    const sectionHeader = {
        title: 'Our community stands with us'
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="p-0 pb-16 center-content" />
                    <div className="community-content">
                        <div className="community-column">
                            <Image
                                src={require('./../../assets/images/community-1.png')}
                                width={175}
                                height={170} />
                            <Image
                                src={require('./../../assets/images/community-2.png')}
                                width={175}
                                height={170} />
                        </div>

                        <div className="community-column">
                            <Image
                                src={require('./../../assets/images/community-3.png')}
                                width={175}
                                height={248} />
                            <Image
                                src={require('./../../assets/images/community-4.png')}
                                width={175}
                                height={248} />
                        </div>

                        <div className="community-column multiple">
                            <Image
                                src={require('./../../assets/images/community-us.png')}
                                width={389}
                                height={304} />
                            <div className="community-row">
                                <Image
                                    src={require('./../../assets/images/community-5.png')}
                                    width={175}
                                    height={248} />
                                <Image
                                    src={require('./../../assets/images/community-6.png')}
                                    width={175}
                                    height={248} />
                            </div>
                        </div>

                        <div className="community-column">
                            <Image
                                src={require('./../../assets/images/community-7.png')}
                                width={175}
                                height={248} />
                            <Image
                                src={require('./../../assets/images/community-8.png')}
                                width={175}
                                height={248} />
                        </div>

                        <div className="community-column">
                            <Image
                                src={require('./../../assets/images/community-9.png')}
                                width={175}
                                height={170} />
                            <Image
                                src={require('./../../assets/images/community-you.svg').default}
                                width={175}
                                height={170} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
