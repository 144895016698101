import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    'm-0',
    className
  );

  const width = props.width ? props.width : 64
  const height = props.height ? props.height : 64

  return (
    <div
      {...props}
      className={classes}
    >
      <Link to="/">
        <Image
          src={require(`./../../../assets/images/${props.logoName}`)}
          alt="Open"
          width={width}
          height={height} />
      </Link>
    </div>
  );
}

export default Logo;