import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import Journey from '../components/sections/FeaturesTiles';
import Team from '../components/sections/Team';
import Partners from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Community from '../components/sections/Community';
import Cta from '../components/sections/Cta';

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      <Journey />
      <Team />
      <Partners invertMobile imageFill className="illustration-section-02" />
      <Testimonial />
      <Community />
      <Cta split />
    </>
  );
}

export default Home;