import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';
import Socials from './partials/Socials';
import Image from '../elements/Image';
import { Link } from 'react-router-dom';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

const Footer = ({
  className,
  topOuterDivider,
  topDivider,
  ...props
}) => {

  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className
  );

  return (
    <footer
      {...props}
      className={classes}
    >
      <div className="container">
        <div className={
          classNames(
            'site-footer-inner',
            topDivider && 'has-top-divider'
          )}>
          <div className="footer-top space-between text-xxs">
            <Logo logoName='logo-footer.png' width='220' height='122' />
            <div>
              <div className="stores">
                <a href="https://apps.apple.com/ro/app/do-it-app/id6449163473" target="_blank" rel="noopener noreferrer">
                  <Image
                    src={require(`./../../assets/images/appstore.svg`).default}
                    alt="AppStore"
                    width={114}
                    height={33} />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.do_it.do_it_app" target="_blank" rel="noopener noreferrer">
                  <Image
                    src={require(`./../../assets/images/googleplay.svg`).default}
                    alt="Google Play"
                    width={114}
                    height={33} />
                </a>
              </div>
              <div className="docs pt-24">
                <Link to="/app-terms-and-conditions" className="footer-data">
                  Terms and Conditions
                </Link>
                <Link to="/app-privacy-policy" className="footer-data">
                  Privacy Policy
                </Link>
              </div>
            </div>
            <div className="footer-social">
              <p>Follow us:</p>
              <Socials />
            </div>
            <a href="https://www.buymeacoffee.com/doitapp">
              <Image
                src={require(`./../../assets/images/buyusacoffee.svg`).default}
                alt="Buy us a coffee"
                width={208}
                height={60} />
            </a>
          </div>
          <div className="footer-bottom space-between text-xxs">
            <div className="footer-data">© 2022 INNOVALISTA SRL. <br />All rights reserved.</div>
            <Link to="/website-terms-and-conditions" className="footer-data">
              Terms and Conditions
            </Link>
            <Link to="/website-privacy-policy" className="footer-data">
              Privacy Policy
            </Link>
            <a className="footer-data"
              href="https://doitapp-landingpage.cdn.prismic.io/doitapp-landingpage/d66d642b-4511-47b6-85f7-72913af5b51e_2022-12-28_Cookies+Policy_Website+DO-IT.docx.pdf">
              Cookie Policy
            </a>
            <Link to="#" id="open_preferences_center" className="footer-data">Update cookie preferences</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;

//document.data.body[1].primary.terms_and_conditions.url
