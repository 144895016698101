import axios from "axios";
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Image from '../elements/Image';
import Button from '../elements/Button';
import Checkbox from '../elements/Checkbox';
import SectionHeader from './partials/SectionHeader';

const formUrl = "https://formbold.com/s/6rnD6"

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const [inputs, setInputs] = useState({
    name: "",
    email: ""
  });
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false
  })

  const [show, setShow] = useState(false);

  const handleOnChange = (event) => {
    event.persist();
    setInputs((prev) => ({
      ...prev,
      [event.target.id]: event.target.value,
    }));
  };
  const handleOnCheckboxChange = (event) => {
    event.persist()
    setCheckboxes((prev) => ({
      ...prev,
      [event.target.id]: event.target.checked
    }));
  }

  const handleOnSubmit = (event) => {
    event.preventDefault();

    axios({
      method: "POST",
      url: formUrl,
      data: inputs,
    })
      .then((r) => {
        setShow(true);

        setTimeout(() => {
          setShow(false);
        }, 4000);
        setInputs({ name: "", email: "", checkbox1: false, checkbox2: false })
        setCheckboxes({ checkbox1: false, checkbox2: false })
      })
      .catch((r) => {
        console.log("Error while persisting subscriber details.");
      });
  };

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  const sectionHeader = {
    title: 'Join us!'
  };

  return (
    <section
      {...props}
      className={outerClasses}
      id="join-us"
    >
      <div className="container">
        <div className={innerClasses}>
          <div className="cta-content">
            <div>
              <Image
                src={require('./../../assets/images/cta.svg').default}
                alt="Cta"
                width={676}
                height={640} />
            </div>
            <div className="cta-text">
              <SectionHeader data={sectionHeader} className="p-0 pb-16" />
              <p className="m-0 mb-16">
                You seem like a fun one who likes to try new experiences
                that come your way. We’ve been looking everywhere
                to find you.
              </p>
              <p className="m-0 mb-16">
                DO-IT App is still under development, but we’d love for you:
                <ul className="mt-8">
                  <li>to join our community</li>
                  <li>test DO-IT App</li>
                  <li>or just get notified when we launch</li>
                </ul>
              </p>
              <p className="m-0 mb-16">
                Subscribe to our email and we will get back to you shortly!
              </p>
              <form onSubmit={handleOnSubmit} autoComplete="off">
                <div className="cta-action m-0 mb-16">
                  <Input
                    id="name"
                    type="text"
                    className="cta-input"
                    label="Your Name"
                    labelHidden
                    placeholder="Your Name"
                    value={inputs.name}
                    handleOnChange={handleOnChange}
                    required
                  />
                  <Input
                    id="email"
                    type="email"
                    className="cta-input"
                    label="Your Email"
                    labelHidden
                    placeholder="Your Email"
                    value={inputs.email}
                    handleOnChange={handleOnChange}
                    required
                  />
                </div>
                <div className="mb-16 checkboxes">
                  <Checkbox
                    id="checkbox1"
                    checked={checkboxes.checkbox1}
                    label="I expressly agree to be informed of the latest updates about DO-IT App"
                    handleOnChange={handleOnCheckboxChange}
                    required
                  />
                  <Checkbox
                    id="checkbox2"
                    checked={checkboxes.checkbox2}
                    label="I expressly agree with the Terms and Conditions, Privacy Policy and Cookie Policy"
                    handleOnChange={handleOnCheckboxChange}
                    required
                  />
                </div>
                <div className="cta-action m-0 mb-16">
                  <Button tag="button" color="primary" wideMobile type="submit">
                    Let's DO-IT
                  </Button>
                </div>
              </form>
              {show && <p>Thanks for subscribing, doer. You’ll hear from us shortly, stay tuned!</p>}
            </div>
          </div>
        </div>
      </div>
    </section >
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;