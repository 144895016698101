import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import Socials from '../layout/partials/Socials';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className="hero-content reveal-from-bottom" data-reveal-delay="200">
            <div className="illustration-three-lines">
              <h1 className="mt-0 mb-16">
                Dare more. Do more. Have fun.
              </h1>
              <p className="m-0 mb-32">
                Grow your skills with DO-IT App challenge by challenge. Exploring new activites every day has never been more fun.
              </p>
              <span>
                <a style={{display: "inline-block"}} href="https://apps.apple.com/ro/app/do-it-app/id6449163473" target="_blank"
                   rel="noopener noreferrer">
                  <Image
                      src={require(`./../../assets/images/appstore.svg`).default}
                      alt="AppStore"
                      width={114}
                      height={33}/>
                </a>
                <a style={{display: "inline-block"}} href="https://play.google.com/store/apps/details?id=com.do_it.do_it_app" target="_blank"
                   rel="noopener noreferrer">
                  <Image
                      src={require(`./../../assets/images/googleplay.svg`).default}
                      alt="Google Play"
                      width={114}
                      height={33}/>
                </a>
              </span>
            </div>
            <div className="hero-image">
              <Image
                  src={require('./../../assets/images/hero-image.svg').default}
                  alt="Hero"
                  width={900}
                  height={504}/>
            </div>
          </div>
          <Socials className="reveal-from-bottom" data-reveal-delay="600"/>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;