import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import WebsitePrivacyPolicy from "./views/WebsitePrivacyPolicy";
import AppPrivacyPolicy from "./views/AppPrivacyPolicy";
import AppTermsAndConditions from "./views/AppTermsAndConditions";
import WebsiteTermsAndConditions from "./views/WebsiteTermsAndConditions";

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/website-privacy-policy" component={WebsitePrivacyPolicy} layout={LayoutDefault} />
          <AppRoute exact path="/app-privacy-policy" component={AppPrivacyPolicy} layout={LayoutDefault} />
          <AppRoute exact path="/app-terms-and-conditions" component={AppTermsAndConditions} layout={LayoutDefault} />
          <AppRoute exact path="/website-terms-and-conditions" component={WebsiteTermsAndConditions} layout={LayoutDefault} />
        </Switch>
      )} />
  );
}

export default App;