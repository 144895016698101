import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import OwlCarousel from "react-owl-carousel"
import arrowLeft from "./../../assets/images/arrow-left.svg";
import arrowRight from "./../../assets/images/arrow-right.svg";
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Our testers enjoyed the experience',
  };

  const prevIcon = '<img src="./../../assets/images/arrow-left.svg">';
  const nextIcon = '<img src="./../../assets/images/arrow-right.svg">';

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <Image
        className="ellipse-up"
        src={require('./../../assets/images/ellipse-up.svg').default}
        alt="ellipse"
        height={120} />
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <OwlCarousel smartSpeed={700} className="owl-theme" items={1} loop nav dots navText={['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>']} navContainerClass={'custom-nav'}>

              <div className="tiles-item">
                <Image
                  className="testimonial-frame"
                  src={require('./../../assets/images/testimonial-frame.svg').default}
                  width={305}
                  height={417} />
                <div className="tiles-item-inner">
                  <div className="testimonial-item-content">
                    <div className="testimonial-item-text">
                      <h5 className="mb-0">
                        If you want to feel challenged, <br />improve your skills or develop some new ones, <br /> DO-IT is the perfect way to start.
                      </h5>
                      <div className="testimonial-item-name">
                        <Image
                          src={require('./../../assets/images/testimonial-name.svg').default}
                          alt="ellipse"
                          width={245}
                          height={261} />
                        <p className="text-xs mb-0">Stefi V.</p>
                      </div>
                    </div>
                    <Image
                      src={require('./../../assets/images/testimonial-1.svg').default}
                      alt="ellipse"
                      width={53}
                      height={21} />
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <Image
                  className="testimonial-frame"
                  src={require('./../../assets/images/testimonial-frame.svg').default}
                  width={305}
                  height={417} />
                <div className="tiles-item-inner">
                  <div className="testimonial-item-content">
                    <div className="testimonial-item-text">
                      <h5 className="mb-0">
                        I was part of the demo testing and I loved the intuitive and sleek app, with the focus on - you guessed it - doing things.
                      </h5>
                      <div className="testimonial-item-name">
                        <Image
                          src={require('./../../assets/images/testimonial-name.svg').default}
                          alt="ellipse"
                          width={245}
                          height={261} />
                        <p className="text-xs mb-0">Beny S.</p>
                      </div>
                    </div>
                    <Image
                      src={require('./../../assets/images/testimonial-2.svg').default}
                      alt="ellipse"
                      width={53}
                      height={21} />
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <Image
                  className="testimonial-frame"
                  src={require('./../../assets/images/testimonial-frame.svg').default}
                  width={305}
                  height={417} />
                <div className="tiles-item-inner">
                  <div className="testimonial-item-content">
                    <div className="testimonial-item-text">
                      <h5 className="mb-0">
                        A very intuitive, well-structured app that will bring you daily challenges that are hard to refuse.
                      </h5>
                      <div className="testimonial-item-name">
                        <Image
                          src={require('./../../assets/images/testimonial-name.svg').default}
                          alt="ellipse"
                          width={245}
                          height={261} />
                        <p className="text-xs mb-0">Dani D.</p>
                      </div>
                    </div>
                    <Image
                      src={require('./../../assets/images/testimonial-3.svg').default}
                      alt="ellipse"
                      width={53}
                      height={21} />
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <Image
                  className="testimonial-frame"
                  src={require('./../../assets/images/testimonial-frame.svg').default}
                  width={305}
                  height={417} />
                <div className="tiles-item-inner">
                  <div className="testimonial-item-content">
                    <div className="testimonial-item-text">
                      <h5 className="mb-0">
                        I think DO-IT is exactly what I was looking for. I like the idea of developing certain skills while using an app for socializing.
                      </h5>
                      <div className="testimonial-item-name">
                        <Image
                          src={require('./../../assets/images/testimonial-name.svg').default}
                          alt="ellipse"
                          width={245}
                          height={261} />
                        <p className="text-xs mb-0">Gheorghe G.</p>
                      </div>
                    </div>
                    <Image
                      src={require('./../../assets/images/testimonial-4.svg').default}
                      alt="ellipse"
                      width={53}
                      height={21} />
                  </div>
                </div>
              </div>

            </OwlCarousel>

          </div>
        </div>
      </div>
      <Image
        className="ellipse-down"
        src={require('./../../assets/images/ellipse-down.svg').default}
        alt="ellipse"
        height={120} />
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;