import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0 illustration-fearless-doer-3',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'We all got a fearless doer inside of us',
    paragraph: 'Are you ready to set yours free?'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content illustration-fearless-doer-1 illustration-fearless-doer-2" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom tile-1">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header invert-color">
                  <div className="index">
                    <h6>01</h6>
                  </div>
                  <div class="title">
                    <h6>Choose interests</h6>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <div className="tooltip tooltip-top">
                    <h5 className="mb-12">Choose your interests</h5>
                    <p>Pick a few skills you want to focus on.</p>
                  </div>
                </div>
              </div>
            </div>


            <div className="tiles-journey reveal-from-bottom" data-reveal-delay="200">
              <Image
                src={require(`./../../assets/images/journey-1.svg`).default}
                alt="journey-2"
                width={143}
                height={274}
              />
            </div>

            <div className="tiles-item reveal-from-bottom tile-2" data-reveal-delay="400">
              <div className="tiles-item-inner reverse">
                <div className="features-tiles-item-header invert-color">
                  <div className="index">
                    <h6>02</h6>
                  </div>
                  <div class="title">
                    <h6>Join challenge</h6>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <div className="tooltip tooltip-bottom">
                    <h5 className="mb-12">Join a Challenge</h5>
                    <p>Start exploring challenges. <br />Find one. Press join.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="tiles-journey reveal-from-bottom" data-reveal-delay="600">
              <Image
                src={require(`./../../assets/images/journey-2.svg`).default}
                alt="journey-2"
                width={143}
                height={274}
              />
            </div>

            <div className="tiles-item reveal-from-bottom tile-3" data-reveal-delay="800">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header invert-color">
                  <div className="index">
                    <h6>03</h6>
                  </div>
                  <div class="title">
                    <h6>Share to inspire</h6>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <div className="tooltip tooltip-top">
                    <h5 className="mb-12">Share your achievement</h5>
                    <p>Upload a photo or a video doing the Challenge. Share it.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;