import * as prismic from '@prismicio/client';

export const repositoryName = 'doitapp-landingpage';

export const client = prismic.createClient(repositoryName, {
    routes: [
        {
            type: 'home_page',
            path: '/',
        },
    ],
})