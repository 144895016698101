import React from 'react';

const WebsitePrivacyPolicy = () => {
  const rawHTML = `<h4 style="padding-top: 2pt;text-indent: 0pt;text-align: center;">PRIVACY POLICY</h4><p
          style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br/></p>
      <ol id="l1">
        <li data-list-text="1."><h4 style="padding-left: 40pt;text-indent: -35pt;text-align: left;">INTRODUCTION</h4><p
            style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: justify;">We are
          ensuring you that for us confidentiality is valuable. We are not interested in your Personal data except those
          which allow us to send you the newsletter through the Contact form on the Website. We shall not give your
          Personal data to publicity agencies or third parties.</p><p
            style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: justify;">The purpose of this
          document is to describe how we collect, use and process your Personal data when using the Website and the
          Contact form on the Website and your rights regarding your Personal data.</p><p
            style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: justify;">In the absence of contrary
          stipulation, the terms written in capitals in this document shall have the meaning described in the document
          „Terms and conditions”.</p><p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br/></p><p
            style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: justify;">By using the Website and
          the Contact form on the Website, you declare to agree with the collection, storage, use and distribution of
          your personal data as described in this Privacy Policy. If you do not agree with this Privacy Policy in whole
          or in part, you should not use the Website and the Contact form on the Website.</p><p
            style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br/></p></li>
        <li data-list-text="2."><h4 style="padding-left: 40pt;text-indent: -35pt;text-align: left;">THE COMPANY, AS A
          CONTROLLER</h4>
          <ol id="l2">
            <li data-list-text="2.1."><p
                style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: justify;">The
              Company shall process your Personal data, as controller according to art. 4 point 7 of the Regulation (EU)
              no. 2016/679, acting on its own behalf.</p><p style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
              <br/></p></li>
          </ol>
        </li>
        <li data-list-text="3."><h4 style="padding-left: 40pt;text-indent: -35pt;text-align: left;">TYPE OF DATA
          COLLECTED, STORED AND USED</h4>
          <ol id="l3">
            <li data-list-text="3.1."><p
                style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: justify;">You
              can also visit our Website without revealing your identity or provide the slightest information regarding
              your Personal data. However, we can automatically obtain information, at the moment of accessing the
              Website, the web server registering the name of your internet provider and the browser from which you are
              visiting us, but this information cannot be associated with any person.</p></li>
            <li data-list-text="3.2."><p
                style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: justify;">When accessing the
              Contact form on the Website, in order to subscribe to our newsletter, we shall ask you the following
              Personal data:</p>
              <ol id="l4">
                <li data-list-text="a)"><p style="padding-left: 67pt;text-indent: -26pt;text-align: justify;">First name
                  and last name;</p></li>
                <li data-list-text="b)"><p
                    style="padding-top: 1pt;padding-left: 67pt;text-indent: -26pt;text-align: justify;">E-mail
                  address.</p></li>
              </ol>
            </li>
            <li data-list-text="3.3."><p
                style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: justify;">When
              visiting our Website, some data is automatically transmitted by your browser and they are collected and
              stored by us, but only if you consent to. This data includes, without limitation to:</p>
              <ol id="l5">
                <li data-list-text="a)"><p style="padding-left: 67pt;text-indent: -26pt;text-align: left;">IP addresses
                  (internet protocol);</p></li>
                <li data-list-text="b)"><p
                    style="padding-top: 1pt;padding-left: 67pt;text-indent: -26pt;text-align: left;">Type of the browser
                  and its version;</p></li>
                <li data-list-text="c)"><p
                    style="padding-top: 1pt;padding-left: 67pt;text-indent: -26pt;text-align: left;">Type of the device
                  used and the operation system;</p></li>
                <li data-list-text="d)"><p
                    style="padding-top: 1pt;padding-left: 67pt;text-indent: -26pt;text-align: left;">Date and hour of
                  access of our Website;</p></li>
                <li data-list-text="e)"><p
                    style="padding-top: 1pt;padding-left: 67pt;text-indent: -26pt;text-align: left;">Your location.</p>
                </li>
              </ol>
            </li>
            <li data-list-text="3.4."><p
                style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: justify;">Some
              information which is relevant for using the Website can be stored on your devices (desktop computers,
              laptops or mobile) under the form of cookies. These cookies help improve the use of our Website and they
              also help us to better understand your interaction with our Website and Contact form. It is possible to
              deactivate the collection of these Personal data by blocking the cookies from the browser settings, but
              some functions of our Website could be affected.</p></li>
            <li data-list-text="3.5."><p
                style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: justify;">We shall also collect
              all the correspondence which you are transmitting to us, such as notifications, questions, and
              requests.</p></li>
          </ol>
        </li>
        <li data-list-text="4."><h4 style="padding-top: 1pt;padding-left: 40pt;text-indent: -35pt;text-align: left;">THE
          SOURCE OF THE USER’S PERSONAL DATA</h4>
          <ol id="l6">
            <li data-list-text="4.1."><p
                style="padding-top: 1pt;padding-left: 40pt;text-indent: -35pt;text-align: left;">The Personal data is
              obtained directly from you as per art. 3 above.</p><p
                style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br/></p></li>
          </ol>
        </li>
        <li data-list-text="5."><h4 style="padding-left: 40pt;text-indent: -35pt;text-align: left;">LEGAL BASIS AND
          PURPOSE OF PERSONAL DATA PROCESSING</h4>
          <ol id="l7">
            <li data-list-text="5.1."><p
                style="padding-top: 1pt;padding-left: 40pt;text-indent: -35pt;text-align: left;">The Company will
              collect your Personal data based on the agreement.</p></li>
            <li data-list-text="5.2."><p
                style="padding-top: 1pt;padding-left: 40pt;text-indent: -35pt;text-align: left;">The Company will
              collect your Personal data for the purpose of:</p>
              <ol id="l8">
                <li data-list-text="a)"><p
                    style="padding-top: 1pt;padding-left: 67pt;text-indent: -26pt;text-align: left;">providing access to
                  the Website;</p></li>
                <li data-list-text="b)"><p
                    style="padding-top: 1pt;padding-left: 67pt;text-indent: -26pt;text-align: left;">sending the
                  newsletter to the Users that subscribed to it.</p><p
                    style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br/></p></li>
              </ol>
            </li>
          </ol>
        </li>
        <li data-list-text="6."><h4 style="padding-left: 40pt;text-indent: -35pt;text-align: left;">THE USE OF PERSONAL
          DATA AND THE RECIPIENT OF PERSONAL DATA</h4>
          <ol id="l9">
            <li data-list-text="6.1."><p
                style="padding-top: 1pt;padding-left: 40pt;text-indent: -35pt;text-align: left;">Your Personal data
              shall be used by the Company as follows:</p>
              <ol id="l10">
                <li data-list-text="a)"><p
                    style="padding-top: 1pt;padding-left: 67pt;text-indent: -26pt;text-align: left;">For providing the
                  access to the Website;</p></li>
                <li data-list-text="b)"><p
                    style="padding-top: 1pt;padding-left: 68pt;text-indent: -27pt;line-height: 114%;text-align: left;">For
                  sending you newsletters (only as consequence of using the Contact form on our Website and only if you
                  agree);</p></li>
                <li data-list-text="c)"><p style="padding-left: 67pt;text-indent: -26pt;text-align: left;">For
                  contacting you when necessary.</p></li>
              </ol>
            </li>
            <li data-list-text="6.2."><p
                style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: left;">We are
              reserving the right to retain your Personal data, if necessary, in order to fulfill our legal obligation,
              to solve the disputes, etc.</p><p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br/></p>
            </li>
          </ol>
        </li>
        <li data-list-text="7."><h4 style="padding-left: 40pt;text-indent: -35pt;text-align: left;">DURATION OF PERSONAL
          DATA PROCESSING</h4>
          <ol id="l11">
            <li data-list-text="7.1."><p
                style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: justify;">We
              shall store your Personal data as long as necessary in order to send you the newsletter to which you
              subscribed through the Contact form and to give you access to the Website.</p><p
                style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br/></p></li>
          </ol>
        </li>
        <li data-list-text="8."><h4 style="padding-left: 40pt;text-indent: -35pt;text-align: left;">THE ACCESS OF THE
          DATA</h4>
          <ol id="l12">
            <li data-list-text="8.1."><p
                style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: justify;">Your
              Personal data shall be accessed only by the employees, contractors, and partners of the Company and only
              for purposes mentioned above.</p><p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br/></p>
            </li>
          </ol>
        </li>
        <li data-list-text="9."><h4 style="padding-left: 40pt;text-indent: -35pt;text-align: left;">USERS’
          OBLIGATIONS</h4>
          <ol id="l13">
            <li data-list-text="9.1."><p
                style="padding-top: 1pt;padding-left: 40pt;text-indent: -35pt;text-align: justify;">As User of our
              Website, you have the following obligations:</p>
              <ol id="l14">
                <li data-list-text="a)"><p
                    style="padding-top: 1pt;padding-left: 68pt;text-indent: -27pt;line-height: 114%;text-align: justify;">to
                  provide true, exact and complete Personal data about you when accessing the Contact form on the
                  Website, as requested in the Contact form. If your Personal data are not true, exact and complete, you
                  have the obligation to notify us as soon as possible in order to remedy this situation;</p></li>
                <li data-list-text="b)"><p style="padding-left: 67pt;text-indent: -26pt;text-align: justify;">to
                  maintain and renew your Data to be true, exact and complete, when necessary.</p><p
                    style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br/></p></li>
              </ol>
            </li>
          </ol>
        </li>
        <li data-list-text="10."><h4 style="padding-left: 40pt;text-indent: -35pt;text-align: left;">USERS’ RIGHTS</h4>
          <ol id="l15">
            <li data-list-text="10.1."><p
                style="padding-top: 1pt;padding-left: 40pt;text-indent: -35pt;text-align: justify;">As User of our
              Website, you have the following rights:</p>
              <ol id="l16">
                <li data-list-text="a)"><p
                    style="padding-top: 1pt;padding-left: 68pt;text-indent: -27pt;line-height: 114%;text-align: justify;">the
                  right to obtain, through a written, dated and signed request and, in general, free of charge, the <u>confirmation
                    that your Personal data are processed</u> or not by us, our business partners, purposes of the
                  processing, duration of processing;</p></li>
                <li data-list-text="b)"><p
                    style="padding-left: 68pt;text-indent: -27pt;line-height: 114%;text-align: justify;">the right to
                  obtain, through a written, dated and signed request and, in general, free of charge, the <u>rectification,
                    update, blocking or deletion of Personal data</u> whose processing is not made according to the EU
                  Regulation no. 2016/679, especially of incomplete or inaccurate Personal data, their transformation
                  into anonymous data, as well as the notification of any of these actions to third parties to which the
                  Personal data have been disclosed;</p></li>
                <li data-list-text="c)"><p
                    style="padding-top: 2pt;padding-left: 68pt;text-indent: -27pt;line-height: 114%;text-align: justify;">the
                  right to <u>request us to transmit to other controllers your Personal data</u> (if technically
                  possible);</p></li>
                <li data-list-text="d)"><p
                    style="padding-left: 68pt;text-indent: -27pt;line-height: 114%;text-align: justify;">the <u>right to
                  object</u>, at any time, for legitimate reasons related to your particular situation, <u>to the
                  processing of your Personal data</u>, except for cases when there are contrary legal provisions; in
                  case of justified objection, the processing of those Personal data cannot take place anymore;</p></li>
                <li data-list-text="e)"><p
                    style="padding-left: 68pt;text-indent: -27pt;line-height: 114%;text-align: justify;">the <u>right to
                  withdraw consent</u> given for direct marketing purposes from us (i.e. the newsletter);</p></li>
                <li data-list-text="f)"><p
                    style="padding-left: 68pt;text-indent: -27pt;line-height: 114%;text-align: justify;">the <u>right of
                  access of the competent courts of law / supervisory authority</u> in order to protect the rights
                  provided by the EU Regulation no. 2016/679.</p></li>
              </ol>
            </li>
            <li data-list-text="10.2."><p
                style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: justify;">To exercise the
              above-mentioned rights, you may address a written, dated and signed request us, using the following <b>contact
                details</b>:</p><h4 style="padding-left: 41pt;text-indent: 0pt;text-align: left;">INNOVALISTA
              S.R.L.</h4><p style="padding-top: 1pt;padding-left: 41pt;text-indent: 0pt;text-align: left;"><b>To the
              attention of</b>: Daria Cup<span class="s2">ă</span>reanu</p><h4
                style="padding-top: 1pt;padding-left: 41pt;text-indent: 0pt;text-align: left;">E-mail address<a
                href="mailto:hello@do-it-app.com" class="a" target="_blank">: </a><a href="mailto:hello@do-it-app.com"
                                                                                     target="_blank">hello@do-it-app.com</a>
            </h4><p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br/></p></li>
          </ol>
        </li>
        <li data-list-text="11."><h4 style="padding-left: 40pt;text-indent: -35pt;text-align: left;">OUR
          OBLIGATIONS</h4>
          <ol id="l17">
            <li data-list-text="11.1."><p
                style="padding-top: 1pt;padding-left: 40pt;text-indent: -35pt;text-align: justify;">We undertake to
              observe the following obligations:</p>
              <ol id="l18">
                <li data-list-text="a)"><p
                    style="padding-top: 1pt;padding-left: 67pt;text-indent: -26pt;text-align: justify;">to act strictly
                  for the purposes mentioned at art. 5.2. above;</p></li>
                <li data-list-text="b)"><p
                    style="padding-top: 1pt;padding-left: 68pt;text-indent: -27pt;line-height: 114%;text-align: justify;">to
                  apply appropriate technical and organizational measures to protect Personal data against accidental or
                  unlawful destruction, loss, modification, disclosure, or unauthorized access, and against any other
                  form of illegal processing, thereby understanding that we will apply all technical and organizational
                  measures necessary to pseudonymised Personal data, minimize Personal data, integrate the necessary
                  safeguards in the processing.</p><p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br/>
                </p></li>
              </ol>
            </li>
          </ol>
        </li>
        <li data-list-text="12."><h4 style="padding-left: 40pt;text-indent: -35pt;text-align: left;">PLACE OF STORAGE OF
          PERSONAL DATA AND TRANSFER OF PERSONAL DATA</h4>
          <ol id="l19">
            <li data-list-text="12.1."><p
                style="padding-top: 1pt;padding-left: 40pt;text-indent: -35pt;text-align: justify;">Our servers are
              located in Ireland and all Personal data is stored on those servers.</p></li>
            <li data-list-text="12.2."><p
                style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: justify;">Your
              Personal data may be transferred in other states in the European Union, without your consent or your prior
              notice. If we intend to transfer your Personal data to states from outside the European Union, you shall
              be previously informed about such transfer.</p><p
                style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br/></p></li>
          </ol>
        </li>
        <li data-list-text="13."><h4 style="padding-left: 40pt;text-indent: -35pt;text-align: left;">AMENDMENT OF THIS
          PRIVACY POLICY</h4>
          <ol id="l20">
            <li data-list-text="13.1."><p
                style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: left;">We are
              reserving the right to amend this Privacy Policy from time to time, as a consequence of the legislative
              changes or of a type of the Website.</p></li>
            <li data-list-text="13.2."><p
                style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: left;">The up-dated Privacy
              Policy shall be published on the Website and shall enter into force at its publication.</p></li>
            <li data-list-text="13.3."><p
                style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: left;">By continuing using the
              Website after the entering into force of the new Privacy Policy, you agree to observe it in full.</p></li>
            <li data-list-text="13.4."><p style="padding-left: 40pt;text-indent: -35pt;text-align: left;">You are
              advised to periodically verify the Privacy Policy.</p></li>
            <li data-list-text="13.5."><p
                style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: left;">If you
              do not want to accept this Privacy Policy, including the subsequent amendments, you must immediately stop
              using the Website.</p><p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br/></p></li>
          </ol>
        </li>
        <li data-list-text="14."><h4 style="padding-left: 40pt;text-indent: -35pt;text-align: left;">LEGAL CHANGES
          REGARDING THE COMPANY</h4></li>
      </ol>
      <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: justify;">If we are
        involved in a reorganization, merger, acquisition procedure or any other change of control or transfer of
        activities, your Personal data can be transferred as part of that transaction without your consent.</p><p
          style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Policy valid as of
        28.12.2022</p>`;

  return (
      <>
          { <div style={{marginLeft: "10%", marginRight: "10%", marginTop: "80pt", marginBottom: "10pt"}} dangerouslySetInnerHTML={{ __html: rawHTML }} /> }
      </>
  );
}

export default WebsitePrivacyPolicy;
